<template>
  <r-e-dialog title="控闸记录" :visible.sync="dialogVisible" top="5vh" width="900px" :show-footer="false">
    <r-e-table ref="communityTableRef" :columns="opencontrolgaterecord" :dataRequest="getFamilyList" :query="formSearch" :height="300">
      <template slot="empty">
        <el-empty/>
      </template>
    </r-e-table>
  </r-e-dialog>
</template>

<script>
import {opencontrolgaterecord} from "@/views/property-management/door-management/data";
import {openLocksLog, getcontrolGateRecordapi} from "@/api/door-management"

export default {
  name: "dialog-show-unlock-record",
  data() {
    return {
      dialogVisible: false,
      dataList: [],
      opencontrolgaterecord,
      type: '',
      uuid: '',
      dateArr: [],
      formSearch: {}
    }
  },
  props: {
    equipmentData: {
      type: Object,
      default: () => ({})
    }
  },
  components: {},
  methods: {
    openDialog(data) {
      this.uuid = data;
      this.dialogVisible = true;
    },
    getFamilyList(params) {
      let {uuid} = this;
      // params.type = this.type;
      if (this.dateArr && this.dateArr.length !== 0) {
        let [startDate, endDate] = this.dateArr;
        params.startDate = startDate;
        params.endDate = endDate;
      }
      console.log({uuid, ...params})
      return getcontrolGateRecordapi({apartmentUuid:uuid, ...params});
    },
    handleSearch() {
      this.$refs["communityTableRef"].pNumber = 1;
      this.$refs["communityTableRef"].getTableData();
    }
  },
}
</script>

<style scoped>

</style>